<template>
  <b-card-code v-if="numerologies">
    <div
      v-for="(numerology, index) in numerologies"
      :key="numerology.id"
    >
      <hr v-if="index > 0 && numerology.define === 'define'">
      <h2
        v-if="renderTitle(numerology)"
        :class="titleMarginTop(index)"
        class="mb-1 font-weight-bolder"
      >
        {{ renderTitle(numerology) }}
      </h2>
      <div class="pl-1">
        <h3>
          {{ numerology.title }}
        </h3>
        <div
          v-for="(content, contentKey) in numerology.content"
          :key="contentKey"
          class="text-justify pl-1"
        >
          <h4 v-if="content[0] === '*'">
            {{ content.substring(1) }}
          </h4>
          <p v-else>
            {{ content }}
          </p>
        </div>
      </div>

    </div>
  </b-card-code>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('numerologies')

export default {
  components: {},
  data() {
    return {
      type: [
        'Con số ngày sinh',
        'Con số nợ nghiệp',
        'Con số đường đời',
        'Con số định mệnh',
        'Con số linh hồn',
        'Con số tính cách',
        'Các bài học nghiệp',
        'Con số trưởng thành',
      ],
    }
  },
  computed: {
    ...mapGetters(['numerologies']),
  },

  created() {
    this.getNumerologies()
  },

  methods: {
    ...mapActions(['getNumerologies']),
    renderTitle(numerology) {
      if (numerology.define !== 'define') {
        return ''
      }
      return this.type[numerology.type]
    },
    titleMarginTop(index) {
      return index === 0 ? '' : 'mt-2'
    },
  },
}
</script>
